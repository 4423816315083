const DocMenuConfig = [
  {
    pages: [
      {
        heading: "Dashboard",
        route: "/dashboard",
        // svgIcon: "media/icons/duotone/Design/PenAndRuller.svg",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Clients Reviews",
        route: "/clients-reviews",
        // svgIcon: "media/icons/duotone/Design/PenAndRuller.svg",
        svgIcon: "media/icons/duotune/general/gen019.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Landing Page",
        route: "/landing-page",
        svgIcon: "media/icons/duotone/Design/PenAndRuller.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Users",
        route: "/users",
        svgIcon: "media/icons/duotone/Design/PenAndRuller.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Subscriptions",
        route: "/subscriptions",
        svgIcon: "media/icons/duotone/Design/PenAndRuller.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Plans",
        route: "/plans",
        svgIcon: "media/icons/duotone/Design/PenAndRuller.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Coupons",
        route: "/coupons",
        svgIcon: "media/icons/duotone/Design/PenAndRuller.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        sectionTitle: "Html Books",
        route: "/html-books",
        svgIcon: "media/icons/duotone/Design/PenAndRuller.svg",
        fontIcon: "bi-app-indicator",
        sub: [
          {
            heading: "Books List",
            route: "/html-books",
          },
          {
            heading: "Create Book",
            route: "/html-books/create-book",
          },
        ]
      },
    ],
  },
];

export default DocMenuConfig;
